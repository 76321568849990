function WelcomeController($rootScope, $scope, $mdDialog) {
  $scope.status = '  ';
  const fullscreen = true;

  let listener;
  let firstRouteChange = true;
  let hidden = false;
  const showAdvanced = (ev) => {
    $mdDialog.show({
      controller: function DialogController($scope, $mdDialog) {
        $scope.cancel = function() {
          $mdDialog.hide();
        };
      },
      templateUrl: 'common/components/welcome.tpl.html',
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose:true,
      fullscreen: true // Only for -xs, -sm breakpoints.
    });
  };

  this.$onInit = () => {
    showAdvanced();

    listener = $rootScope.$on('$locationChangeStart', (evt) => {
      if(firstRouteChange) {
        firstRouteChange = false;
        return;
      }
        
      if(!hidden) {
        $mdDialog.hide();
        hidden = true;
      }
    });
  };

  this.$onDestroy = () => {
    listener();
  };
}

angular.module('sq.common.welcome.component', ['sq.directives.scroll'])
.component('sqWelcome', {
  template: '',
  controller: WelcomeController,
});

